exports.components = {
  "component---packages-themes-gatsby-theme-flexiblocks-src-pages-404-jsx": () => import("./../../../../packages/themes/gatsby-theme-flexiblocks/src/pages/404.jsx" /* webpackChunkName: "component---packages-themes-gatsby-theme-flexiblocks-src-pages-404-jsx" */),
  "component---src-pages-expertise-ai-engineering-index-jsx": () => import("./../../../src/pages/expertise/ai-engineering/index.jsx" /* webpackChunkName: "component---src-pages-expertise-ai-engineering-index-jsx" */),
  "component---src-pages-expertise-mobile-apps-index-jsx": () => import("./../../../src/pages/expertise/mobile-apps/index.jsx" /* webpackChunkName: "component---src-pages-expertise-mobile-apps-index-jsx" */),
  "component---src-pages-expertise-overview-index-jsx": () => import("./../../../src/pages/expertise/overview/index.jsx" /* webpackChunkName: "component---src-pages-expertise-overview-index-jsx" */),
  "component---src-pages-expertise-ui-ux-design-index-jsx": () => import("./../../../src/pages/expertise/ui-ux-design/index.jsx" /* webpackChunkName: "component---src-pages-expertise-ui-ux-design-index-jsx" */),
  "component---src-pages-expertise-web-apps-index-jsx": () => import("./../../../src/pages/expertise/web-apps/index.jsx" /* webpackChunkName: "component---src-pages-expertise-web-apps-index-jsx" */),
  "component---src-pages-index-jsx": () => import("./../../../src/pages/index.jsx" /* webpackChunkName: "component---src-pages-index-jsx" */),
  "component---src-pages-legal-cookie-policy-index-jsx": () => import("./../../../src/pages/legal/cookie-policy/index.jsx" /* webpackChunkName: "component---src-pages-legal-cookie-policy-index-jsx" */),
  "component---src-pages-legal-privacy-policy-index-jsx": () => import("./../../../src/pages/legal/privacy-policy/index.jsx" /* webpackChunkName: "component---src-pages-legal-privacy-policy-index-jsx" */),
  "component---src-pages-services-build-product-index-jsx": () => import("./../../../src/pages/services/build-product/index.jsx" /* webpackChunkName: "component---src-pages-services-build-product-index-jsx" */),
  "component---src-pages-services-extend-product-index-jsx": () => import("./../../../src/pages/services/extend-product/index.jsx" /* webpackChunkName: "component---src-pages-services-extend-product-index-jsx" */),
  "component---src-pages-services-maintainance-index-jsx": () => import("./../../../src/pages/services/maintainance/index.jsx" /* webpackChunkName: "component---src-pages-services-maintainance-index-jsx" */),
  "component---src-pages-services-overview-index-jsx": () => import("./../../../src/pages/services/overview/index.jsx" /* webpackChunkName: "component---src-pages-services-overview-index-jsx" */)
}

